<template>
    <div></div>
</template>

<script>
    export default {
        async beforeCreate() {
            if (this.$route.params.code) {
                let code     = this.$route.params.code;
                let response = await this.func.ax("/php/verify_email.php", {
                    code: code,
                });
                await this.$user.init();
                response = response === "success";
                this.eventbus.$emit("SB", this.$t(response ? "verification.success" : "verification.error"), response ? "success" : "error");
                await this.$router.push(this.$variables.v.navigation.default);
            }
        },
    }
</script>